import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";

const MoreInfoButton = props => {
  return (
    // <Link to={props.to} className={`more-link ${props.className}`}>
   
      <a href="/order"> <button className={`more-link ${props.className}`}>
        
        {props.text}
        <FontAwesomeIcon
            icon={["fas", "long-arrow-alt-right"]}
            className="icon"
          />
        </button>
        
        
        </a> 
  

    // </Link>
  );
};

export default MoreInfoButton;
