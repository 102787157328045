import React from "react";

import Footer from "../index/Footer.jsx";
// alternative 2 page components
import Heading from "../alter2/Heading.jsx";

class Support extends React.Component {
  render() {
    return (
      <>

        <main ref="main">
      
          <Heading />

        </main>
        <Footer />
      </>
    );
  }
}

export default Support;
