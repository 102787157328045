import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer className='site-footer section border-top'>
      <Container className='pb-3'>
        <Row className='gap-y text-center text-md-left'>
          <Col md='6' lg='4' className='mr-auto'>
            <a href='https://www.morphxr.io'>
              <img
                src={require("assets/img/MorphNeon_Trans.png")}
                alt='Morph'
                className='logo'
              />
            </a>
            <p className='lead'>
              <span className='bold'>Morph</span>, how you market.
            </p>
          </Col>

          <Col md='3' lg='4'>
            <h6 className='py-2 bold'>Find us</h6>

            <p>
              We are improving our social presence, please like us or follow us
              to get information on Morph events and new public experiences.
            </p>
            <nav className='nav justify-content-center justify-content-md-start'>
              <a
                href='https://www.instagram.com/morphxr/'
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-circle btn-sm brand-instagram'
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
              <p> </p>
              <a
                href='https://www.twitter.com/morphxr/'
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-circle btn-sm brand-twitter'
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
            </nav>
          </Col>

          <Col md='3'>
            <h6 className='py-2 bold'>Our Products</h6>

            <nav className='nav flex-column'>
              <a
                className='nav-item py-2'
                href='https://apps.apple.com/us/developer/morph-studios-llc-az/id1551840758'
                target='_blank'
                rel='noopener noreferrer'
              >
                App Store
              </a>
              <a
                className='nav-item py-2'
                href='https://play.google.com/store/apps/developer?id=Morph+Studios+LLC+%28AZ%29'
                target='_blank'
                rel='noopener noreferrer'
              >
                Play Store
              </a>
              {/* <a
                className="nav-item py-2"
                href=""
                target=""
                rel="noopener noreferrer"
              >
                Google Play (Coming Soon)
              </a> */}
              {/* <a
                className="nav-item py-2"
                href="https://themeforest.net/user/5studiosnet/portfolio"
                target="_blank"
                rel="noopener noreferrer"
              >
                More ...
              </a> */}
            </nav>
          </Col>
        </Row>

        <hr className='mt-5' />

        <Row className='small align-items-center'>
          <Col md='4'>
            <p className='mt-2 mb-md-0 text-muted text-center text-md-left'>
              © 2021
              <a
                href='https://morphxr.io'
                target='_blank'
                rel='noopener noreferrer'
              >
                Truman Boone
              </a>
              . All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
