import React from "react";
import { Container } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GetStarted = () => {
  return (
    <section className="section bg-contrast edge top-left">
      <Container>
        <div className="d-flex align-items-center flex-column flex-lg-row">
          <div className="text-center text-lg-left">
            <p className="light mb-0 accent lead">Ready to Morph your Brand? Product? or Service?</p>
            <h2 className="mt-0">Let us know how we can help</h2>
          </div>

          <nav className="nav mt-3 mt-lg-0 ml-lg-auto">
            {/* <a
              href="#demos"
              className="btn btn-alternate btn-rounded mr-3 scrollto"
            >
              Check Demos <i className="fas long-arrow-alt-right" />
            </a> */}
            {/* <a
              href="mailto:contact@morphxr.io"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary text-contrast btn-rounded"
            >
              <FontAwesomeIcon
                icon={["fas", "envelope"]}
                className="icon"
              />
              Inquire
            </a> */}

            
          {/* <Nav className="mt-5" tag="nav"> */}
              <a
                href="/pricing"
                // href=":4343"
                className="btn btn-primary text-contrast btn-rounded"
              >
                <FontAwesomeIcon icon={["fas", "tag"]} className="mr-3" />
                Packages &amp; pricing
              </a> 
          </nav>
        </div>
      </Container>
    </section>
  );
};

export default GetStarted;
