import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// routing components
import Index from "views/Index.jsx";

import PricingPage from "views/landing/Pricing.jsx"
import PrivacyPage from "views/landing/Privacy.jsx";
import SupportPage from "views/landing/Support.jsx";
// global template script file
import "./laapp.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Index {...props} />} />

      {/* <Route
        path="/alter-1"
        exact
        render={props => <Alternative1 {...props} />}
      />
      <Route
        path="/alter-2"
        exact
        render={props => <Alternative2 {...props} />}
      />
      <Route
        path="/alter-3"
        exact
        render={props => <Alternative3 {...props} />}
      /> */}
      <Route
        path="/support"
        exact
        render={props => <SupportPage {...props} />}
      />

      <Route
        path="/privacy"
        exact
        render={props => <PrivacyPage {...props} />}
      />
      <Route
        path="/pricing"
        exact
        render={props => <PricingPage {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
