import React from "react";

import Footer from "../index/Footer.jsx";

class Privacy extends React.Component {
  render() {
    return (
      <>
        {/* <DefaultNavbar useOnlyDarkLogo={true} /> */}
        <main ref='main'>
          <div className='container'>
            <h2>Morph Privacy Policy</h2>
            <h5>Information Collection and Use</h5>
            <p>We do not collect any personal identifying information</p>
            <p>We do not share personal data with 3rd parties or advertisers</p>
            <p>
              We do not remotely store your photos, videos, or user information
            </p>
            <p>
              We want to inform you that whenever you use our Service, in a case
              of an error we collect data and information (through third party
              products) on your phone called Log Data. This Log Data may include
              information such as your device Internet Protocol address, device
              name, operating system version, the configuration of the app when
              utilizing our Service, the time and date of your use of the
              Service, and other statistics.
            </p>

            <p>
              Link to 3rd party{" "}
              <a href='https://unity3d.com/legal/privacy-policy'>
                {" "}
                Unity Analytics Privacy Policy
              </a>
            </p>

            <h3>Links to Other Sites</h3>
            <p>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>

            <h3>Children's Privacy</h3>
            <p>We do not collect personal information from children.</p>

            <h3>Location Privacy</h3>
            <p>
              We do not store your location or GPS breadcrumbs when using 'Morph
              Explorer'.
            </p>

            <h3>Changes to This Privacy Policy</h3>
            <p>
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page.
            </p>

            <h3>Contact Us</h3>
            <p>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at{" "}
              <a href='mailto:contact@morphxr.io'>Contact@Morphxr.io</a>.
            </p>

            <p>Last Updated 06/06/2021</p>
          </div>
        </main>

        <Footer />
      </>
    );
  }
}

export default Privacy;
