import React from "react";
import { Container, Row, Col, Nav, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Heading = () => {
  return (
    <header className='header alter3-header section gradient gradient-primary-auxiliary text-contrast'>
      <div className='shapes-container'>
        <div className='animation-shape shape-ring animation--rotating-diagonal'>
          <div />
        </div>
        <div className='animation-shape shape-circle animation--clockwise'>
          <div />
        </div>
        <div className='animation-shape shape-triangle animation--anti-clockwise'>
          <div className='animation--rotating' />
        </div>
        <div className='animation-shape shape-diamond animation--anti-clockwise'>
          <div className='animation--rotating' />
        </div>

        <div className='static-shape shape-ring-1' />
        <div className='static-shape shape-ring-2' />
        <div className='static-shape shape-circle-1' />

        <div className='static-shape pattern-dots-1' />
        <div className='static-shape pattern-dots-2' />

        <div className='static-shape ghost-shape ghost-shape-1' />
      </div>

      <Container className='bring-to-front'>
        <Row>
          <Col md='6'>
            <h1 className='extra-bold display-md-3 font-md'>
              Morph
              <span className='d-block display-md-4 light'>
                how you showcase... everything!
              </span>
            </h1>
            <p className='lead'>
              You deserve an outstanding way to showcase your ideas and
              endeavours. In collaboration with you, Morph will provide an
              augmented reality (AR) marketing experience for your idea, brand
              or business.
            </p>

            <Nav className='mt-5' tag='nav'>
              {/* <NavLink
                href="#!"
                className="mr-3 btn btn btn-rounded btn-contrast"
              >
                <FontAwesomeIcon icon={["fas", "tag"]} className="mr-3" />
                Plans &amp; pricing
              </NavLink> */}

              <NavLink
                href='mailto:contact@morphxr.io?subject=New Morph Client Inquiry&body='
                target='_blank'
                rel='noopener noreferrer'
                className='mr-3 btn btn btn-rounded btn-contrast'
              >
                <FontAwesomeIcon icon={["fas", "envelope"]} className='icon' />
                Inquire
              </NavLink>

              {/* <NavLink
                href="#!"
                className="btn btn-rounded btn-outline-contrast"
              >
                Start now
              </NavLink> */}
            </Nav>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
